import React from "react"
import "../styles/styles.scss"

import Header from "../components/header"
import Demo from "../components/demo"
import Footer from "../components/footer"
import Pricetab from "../components/pricetab"

import {Helmet} from 'react-helmet'

const IndexPage = () => (
    <div>
      <Helmet>
        <html lang="en" />
        <title>Skialabs - de prijzen.</title>
        <description>Prijzen van het smart waste platform.</description>
      </Helmet>
      <Header />
      <Pricetab />
      <Demo />
      <Footer />
    </div>
)

export default IndexPage