import React from "react"

const Pricetab = () => {
    return(
        <div className="price-tab">
        <div className="container">
            <div className="row-main">
                <div className="main-text"> De tarieven. </div>
                <div className="sub-text">  Kies het plan dat bij jou past.  </div>
            </div>
            <div className="row-feat">
                <div className="column-feat">
                    <div className="title">Basic.</div>
                    <div className="title2">80EU <span>per chauffeur per maand</span> </div>
                    <div>Standaard route optimalisatie voor ieder voertuig en ieder type afval. </div>
                    <div>Standaard klantbeheer en facturatie. </div>
                    <div>Koppeling voor exporteren data over opgehaald afval en gereden route.</div>
                    <div> Afvinken, informatie invullen en foto’s maken voor de chauffeur in DispatchX.  </div>
                </div>
                <div className="column-feat">
                    <div className="title">Pro.</div>
                    <div className="title2">120EU <span>per chauffeur per maand</span> </div>
                    <div>Functionaliteiten van het Basic plan. </div>
                    <div>Dashboard met historische statistieken over opgehaald afval, gemaakte meldingen en gereden routes. </div>
                    <div>Dashboard met real-time proces monitor.</div>
                    <div>Automatisch verwerken van gedefinieerde klant- en chauffeur meldingen in de routes en facturatie.  </div>
                </div>
                <div className="column-feat">
                    <div className="title">Enterprise.</div>
                    <div className="title2">Custom</div>
                    <div>Functionaliteiten van het Pro plan. </div>
                    <div>Machine learning engine voor voorspellen van afvalstromen.</div>
                    <div>Data-gedreven route optimalisatie op basis van verwacht afval, drukte in de stad, en optimale verwerking.</div>
                    <div>Gepersonaliseerd klantbeheer, facturatie en onderliggende database.  </div>
                    <div>Volledige onboarding en trainingen.</div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Pricetab